/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";

/** Colors **/
@wrmBlue: #192c59;

@primary: @wrmBlue;
@secondary: #479941;

@topNavColor: #fff;
@topNavTextColor: @wrmBlue;
@leftNavColor: @wrmBlue;
@leftNavSubColor: lighten(color(@wrmBlue), 4%);
@userInfoBackgroundColor: @wrmBlue;
@navigationSubColor: darken(color(@wrmBlue), 9%);

/******************/

// TODO CCC: figure out better way to do these styles
tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

/* side menu Style Overrides */
.skin-blue .left-side,
.skin-blue .main-sidebar,
.skin-blue .wrapper {
    background-color: @leftNavColor;
}

.skin-blue .sidebar-menu > li.active > app-nav-menu-item > a,
.skin-blue .sidebar-menu > li:hover > app-nav-menu-item > a {
    background-color: darken(color(@leftNavColor), 5%);
}

.skin-blue .sidebar-menu > li > .treeview-menu {
    background-color: @leftNavSubColor;
}

/* "NAVIGATION" menu item */
.skin-blue .sidebar-menu > li.header {
    background-color: @navigationSubColor;
}

/* top nav styles*/
.skin-blue .main-header .navbar,
.skin-blue .main-header .navbar,
.skin-blue .main-header .navbar {
    background-color: @topNavColor !important;
    color: @topNavTextColor !important;
}

.skin-blue .main-header .navbar .nav > li > a {
    color: @topNavTextColor !important;
}

.skin-blue .main-header .navbar,
.skin-blue .main-header .navbar,
.skin-blue .main-header .navbar-nav {
    color: @topNavTextColor !important;
}

.skin-blue .main-header .logo,
.skin-blue .main-header .logo {
    background-color: @topNavColor !important;
    color: @topNavTextColor !important;
}

/* custom button styles */
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    opacity: 1;
}

.btn.btn-fab-md,
.btn.btn-fab-spin-lg,
.btn.btn-fab-lg,
.btn.btn-fab-lg,
.btn.btn-fab-md {
    background-color: @primary;
    border-color: @primary;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:visited {
    background-color: @primary;
    border-color: @primary;
}

.btn-primary:hover {
    background-color: lighten(color(@primary), 20%);
    border-color: lighten(color(@primary), 20%);
}

.btn-primary-inverse {
    background-color: #fff;
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:visited {
    background-color: @secondary;
    border-color: @secondary;
    color: #fff;
}

.btn-secondary:hover {
    background-color: lighten(color(@secondary), 20%);
    border-color: lighten(color(@secondary), 20%);
    color: #fff;
}

.btn-success {
    background-color: @secondary;
    border-color: @secondary;
}

.with-cursor {
    cursor: pointer;
}

angular2-multiselect > div.cuppa-dropdown > div.selected-list > div.c-btn > span.ng-star-inserted,
angular2-multiselect > div > div.selected-list > div > div > div > span.c-label.ng-star-inserted > label {
    font-size: 15px !important;
}
